import _ from 'underscore'
{ div, button, span, label, select, option, a } = React.DOM

window.ItemChooser = React.createClass
  displayName: 'ItemChooser'
  getDefaultProps: ->
    renderName: (item)->
      item.name
  getInitialState: ->
    items = @props.items or []
    selected = @props.initialSelected
    if @props.blankItem == false && (!selected? || selected == '')
      selected = items[0]
    items: items
    selected: selected
    showSelect: false
  onStoreChange: ->
    result =
      items: @props.store.getItems()
    if @props.blankItem == false && (!@state.selected || @state.selected == '')
      result.selected = result.items[0]
    @setState result
  onStoreNew: (id)->
    @setState
      selected: id
  componentWillMount: ->
    @props.store.addListener 'new', @onStoreNew
    @props.store.addListener 'change', @onStoreChange
    @props.store.loadItems()
  componentWillUnmount: ->
    @props.store.removeListener 'new', @onStoreNew
    @props.store.removeListener 'change', @onStoreChange
  currentItem: ->
    result = _.findWhere(@state.items, { id: parseInt(@state.selected) })
    if result?
      @props.renderName(result)
    else
      @props.blankItem
  showSelectClass: ->
    if @state.showSelect
      ''
    else
      ' hidden'
  toggleSelect: (e)->
    @setState
      showSelect: !@state.showSelect
  setSelected: (e)->
    @setState
      selected: $(e.target).val()
      showSelect: false
  toggleButtonLabel: ->
    if @state.showSelect
      "Choose #{@props.label}"
    else
      "Change #{@props.label}"
  render: ->
    div { className: 'select input optional form-group chooser' },
      label { className: 'control-label' },
        @props.label
        if @props.info?
          React.createElement MoreInfo,
            info: @props.info
      span { className: 'current inline' }, @currentItem()
      if @state.items.length > 1 || @props.blankItem
        span { className: 'change inline' },
          a { className: 'btn btn-action btn-xs', onClick: @toggleSelect }, @toggleButtonLabel()
      if !@props.allowAdd? || @props.allowAdd
        span { className: 'add inline' },
          React.createElement @props.editor, _.extend {}, @props,
            addOnly: true
      span { className: "form-wrapper#{@showSelectClass()}" },
        select
          className: 'form-control'
          name: @props.inputName
          value: @state.selected
          onChange: @setSelected
        ,
          if @props.blankItem
            option { value: '' }, @props.blankItem
          _.map @state.items, (item)=>
            option { key: "item-chooser-option-#{item.id}", value: item.id },
              @props.renderName(item)

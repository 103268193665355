import _ from 'underscore'

window.RegionChooser = React.createClass
  displayName: 'RegionChooser'
  render: ->
    React.createElement ItemChooser,
      _.extend {}, @props,
        blankItem: false
        editor: RegionEditor
        label: 'Region'
        size: 'sm'

import _ from 'underscore'
window.BrandChooser = React.createClass
  displayName: 'BrandChooser'
  getDefaultProps: ->
    store: ItemStore.createClass '/manage/brands.json', (id)->
      "/manage/brands/#{id}.json"
  render: ->
    React.createElement ItemChooser,
      _.extend {}, @props,
        blankItem: 'Primary Company'
        editor: BrandEditor
        label: 'Brand'

import _ from 'underscore'
{} = React.DOM

window.JobCategories = React.createClass
  displayName: 'JobCategories'
  render: ->
    React.createElement ObjectSetComponent, _.extend {}, @props,
      inputTemplate: 'job[category_ids][]'
      defaultAttrs: {}
      label: ''

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (typeof this.data.get("hints") !== "undefined") {
      this.hints = JSON.parse(this.data.get("hints"));
      this.ensureHelpBlock();
      $(this.element).on("change", this.onChange);
      this.onChange();
    }
  }

  ensureHelpBlock() {
    if (this.helpBlock().length < 1) {
      $(this.element).after('<span class="help-block"></span>');
    }
  }

  helpBlock() {
    return $(this.element).siblings(".help-block");
  }

  onChange = () => {
    const hint = this.hints[$(this.element).val()];
    if (typeof hint !== "undefined") {
      this.helpBlock().html(hint);
    }
  };
}

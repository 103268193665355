import _ from 'underscore'
{ div, form, label, span, fieldset, input, select, option } = React.DOM

window.LocationEditor = React.createClass
  displayName: 'LocationEditor'
  getDefaultProps: ->
    renderName: (item)->
      "#{item.state} - #{item.name}"
  render: ->
    React.createElement ItemEditor, _.extend {}, @props,
      thing: 'location'
      name: 'Location'
      listLabel: 'Existing Locations'
      itemForm: LocationModalBody

LocationSelect = React.createClass
  displayName: 'LocationSelect'
  render: ->
    div { className: 'select input optional form-group item-input', 'data-field': @props.name },
      label { className: 'control-label', htmlFor: "lf_location_#{@props.name}" },
        @props.label
      span { className: 'form-wrapper' },
        select
          id: "lf_location_#{@props.name}"
          className: 'form-control'
          name: "location[#{@props.name}]"
          value: @props.item[@props.name]
          onChange: @props.onChange
        ,
          _.map @props.options, (opt)=>
            option { value: opt[1], key: "#{@props.name}-#{opt[1]}" }, opt[0]

LocationModalBody = React.createClass
  displayName: 'LocationModalBody'

  getInitialState: ->
    users: @props.userStore.getItems() or []
    districts: @props.districtStore.getItems() or []
    locale: @props.locale.initial
    stateLabel: @props.locale[@props.locale.initial].state.label
    stateOptions: @props.locale[@props.locale.initial].state.options
    zipLabel: @props.locale[@props.locale.initial].zip.label

  onUsersChange: ->
    @setState
      users: @props.userStore.getItems()
  onDistrictsChange: ->
    @setState
      districts: @props.districtStore.getItems()
  componentWillMount: ->
    @props.userStore.addListener 'change', @onUsersChange
    @props.districtStore.addListener 'change', @onDistrictsChange
  componentWillUnmount: ->
    @props.userStore.removeListener 'change', @onUsersChange
    @props.districtStore.removeListener 'change', @onDistrictsChange
  availableUsers: ->
    _.reject @state.users, (user)=>
      _.findWhere @props.item.users, { id: user.id }

  changeCountry: (e)->
    val = "en-#{$(e.currentTarget).val()}"
    @setState
      locale: val
      stateLabel: @props.locale[val].state.label
      stateOptions: @props.locale[val].state.options
      zipLabel: @props.locale[val].zip.label

  render: ->
    fieldset { className: 'inputs' },
      div { className: 'row' },
        div { className: 'col-md-8' },
          React.createElement ItemString,
            thing: 'location'
            item: @props.item
            name: 'name'
            label: 'Name'
            required: true
        div { className: 'col-md-4' },
          React.createElement ItemString,
            thing: 'location'
            item: @props.item
            name: 'num'
            label: 'Location Number/ID'
            required: true
            info: 'Can be the same as the name of the location if location does not have an ID.'
      div { className: 'row' },
        div { className: 'col-md-8' },
          React.createElement ItemString,
            thing: 'location'
            item: @props.item
            name: 'street'
            label: 'Street Address'
        div { className: 'col-md-4' },
          React.createElement ItemString,
            thing: 'location'
            item: @props.item
            name: 'city'
            label: 'City'
            required: true
      div { className: 'row' },
        div { className: 'col-md-6' },
          React.createElement LocationSelect,
            item: @props.item
            name: 'state'
            label: @state.stateLabel
            options: @state.stateOptions
        div { className: 'col-md-6' },
          React.createElement ItemString,
            thing: 'location'
            item: @props.item
            name: 'zip'
            label: @state.zipLabel
            required: true
      div { className: 'row' },
        div { className: 'col-md-6' },
          React.createElement LocationSelect,
            item: @props.item
            name: 'country'
            label: 'Country'
            options: @props.locale.countryOptions
            onChange: @changeCountry
        div { className: 'col-md-6' },
          React.createElement ItemString,
            thing: 'location'
            item: @props.item
            name: 'phone'
            label: 'Phone'
      if @state.districts.length > 0
        React.createElement DistrictChooser,
          _.extend {}, @props,
            items: @state.districts
            initialSelected: @props.item.district_id
            store: @props.districtStore
            locationStore: @props.store
            inputName: 'location[district_id]'
            renderName: (item)->
              item.name
            info: 'Locations must be added to the District for the District Manager to have ' +
              'access to them.'
      if @state.users.length > 0
        React.createElement ObjectSetComponent,
          parentId: @props.item.id || @props.item.temp_id
          inputTemplate: 'location[user_ids][]'
          defaultAttrs: {}
          initialItems: @props.item.users
          label: 'Participating Users'
          info: 'Full and Limited Access users must be added to a location to get access to ' +
            'jobs and applicants in that location. Admin users automatically have access to all ' +
            'locations on the account.'
          availableItems: @availableUsers()
          renderName: (item)->
            "#{item.firstname} #{item.lastname}"
          filter: (item)->
            parseInt(item.role) > 1 && parseInt(item.role) < 4
      if @state.users.length > 0
        span { className: 'hint help-block' },
          'Make sure to add full and limited access users who need to see jobs and ' +
            'applicants in this location.'

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template"];

  remove(event) {
    event.preventDefault();

    const dialog_content = this.templateTarget.innerHTML;
    if (dialog_content.length > 0) {
      let m = window.get_modal_markup();
      const buttons = `<button type="button" class="btn btn-default cancel">Cancel</button>
                       <button type="button" class="btn btn-primary continue">Continue</button>`;
      m.html(
        window.get_modal_content("Migrate Applicants", dialog_content, buttons)
      );
      m.addClass("no-close");

      m.find(".modal-footer button.continue").click(() => {
        let the_select = m.find("select.replacement_step");
        $(this.element).append(
          `<input type="hidden" name="${the_select.attr("name")}" value="${the_select.val()}" />`
        );
        m.modal("hide");
        $(this.element).hide();
      });
      m.find(".modal-footer button.cancel").click(() => {
        m.modal("hide");
      });
      m.modal({ backdrop: "static", keyboard: false });
    }
  }
}

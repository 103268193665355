import _ from 'underscore'
{a, i, input, span} = React.DOM

window.StringSetItemComponent = React.createClass
  displayName: 'StringSetItemComponent'
  handleRemove: (event)->
    event.preventDefault()
    @props.onRemove(@props.item)

  render: ->
    if @props.item._destroy == 1
      span {},
        input {
          name: "#{@props.inputTemplate}[#{@props.item.temp_id}][id]",
          value: @props.item.id,
          type: 'hidden'
        }
        input {
          name: "#{@props.inputTemplate}[#{@props.item.temp_id}][_destroy]",
          value: 1,
          type: 'hidden'
        }
    else
      a { href: '#', className: 'btn btn-xs btn-default', onClick: @handleRemove },
        @props.renderName(@props.item)
        i { className: 'fa fa-times' }
        _.map @props.item, (val, key)=>
          unless key == 'temp_id'
            input {
              key: key,
              name: "#{@props.inputTemplate}[#{@props.item.temp_id}][#{key}]",
              value: val,
              type: 'hidden'
            }

$(->
  # CLASS .limit-select : LIMITS CHECKBOX GROUP TO HAVING ONLY data-max BOXES SELECTED

  $(document).on 'change', '.check_boxes.limit-select .checkbox input', ->
    the_wrapper = $(this).closest('.check_boxes')
    if $(this).is(':checked') && the_wrapper.find('input:checked').length > the_wrapper.data('max')
      el = if the_wrapper.find('input:checked').last().attr('id') == $(this).attr('id')
        the_wrapper.find('input:checked').first()
      else
        the_wrapper.find('input:checked').last()
      el.prop('checked', false)
      el.trigger('change')

  # CLASS .exclusive : DENOTES THAT A PARTICULAR CHECKBOX DISABLES ALL OTHERS IN THE CHECKBOX GROUP

  $(document).on 'change', '.check_boxes .checkbox input.exclusive', ->
    the_wrapper = $(this).closest('.check_boxes')
    other = the_wrapper.find('.checkbox input').not($(this))
    if $(this).is(':checked')
      other.prop('checked', false)
      other.trigger('change')
      other.prop('disabled', true)
    else
      other.prop('disabled', false)
)

import _ from 'underscore'
{ div, form, label, span, fieldset, input, select, option } = React.DOM

window.DepartmentEditor = React.createClass
  displayName: 'DepartmentEditor'
  render: ->
    React.createElement ItemEditor, _.extend {}, @props,
      thing: 'department'
      name: 'Department'
      listLabel: 'Existing Departments'
      itemForm: DepartmentModalBody

DepartmentModalBody = React.createClass
  displayName: 'DepartmentModalBody'

  getInitialState: ->
    users: @props.userStore.getItems()
  onUsersChange: ->
    @setState
      users: @props.userStore.getItems()
  componentWillMount: ->
    @props.userStore.addListener 'change', @onUsersChange
  componentWillUnmount: ->
    @props.userStore.removeListener 'change', @onUsersChange
  availableUsers: ->
    _.reject @state.users, (user)=>
      _.findWhere @props.item.users, { id: user.id }

  render: ->
    fieldset { className: 'inputs' },
      React.createElement ItemString,
        thing: 'department'
        item: @props.item
        name: 'name'
        label: 'Name'
        required: true
      React.createElement ObjectSetComponent,
        parentId: @props.item.id || @props.item.temp_id
        inputTemplate: 'department[user_ids][]'
        defaultAttrs: {}
        initialItems: @props.item.users
        label: 'Participating Users'
        availableItems: @availableUsers()
        renderName: (item)->
          "#{item.firstname} #{item.lastname}"
        filter: (item)->
          parseInt(item.role) > 1 && parseInt(item.role) < 4
        info: 'Full and limited access users must be given access to departments in order to ' +
          'see jobs and applicants in those departments.'
      span { className: 'hint help-block' },
        'Make sure to add full and limited access users who need to see jobs and ' +
          'applicants in this department.'

window.NewEmployeeForm = React.createClass({
  handleSubmit: function(e) {
    e.preventDefault();
    var firstname = ReactDOM.findDOMNode(this.refs.firstname).value.trim();
    var lastname = ReactDOM.findDOMNode(this.refs.lastname).value.trim();
    var email = ReactDOM.findDOMNode(this.refs.email).value;

    this.props.onSubmit(
      {
        user: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          role: 4
        }
      }
    );
  },
  render: function() {
    if (this.props.success == true) {
      return (
        <div className="alert alert-success">
          <p>Employee created. <a href="#" onClick={this.props.displayForm}>Add another?</a></p>
        </div>
      );
    }
    if (this.props.showForm == false) {
      return (
        <div className="new-employee">
          <a href="#" onClick={this.props.displayForm}>Add New Employee</a>
        </div>
      )
    }
    return (
      <div className="new-employee">
        <h4>Add New Employee</h4>
        <CPErrors errors={this.props.errors} />
        <form role="form" className="form-inline" name="create-new" onSubmit={this.handleSubmit}>
          <fieldset>
            <input type="hidden" name={this.props.form.csrf_param} value={this.props.form.csrf_token} />
            <div className="form-group">
              <label className="sr-only" htmlFor="applicant[firstname]">
                First Name
              </label>
              <input type="text" name="applicant[firstname]" className="form-control" ref="firstname" placeholder="First Name" required/>
            </div>
            <div className="form-group">
              <label className="sr-only" htmlFor="applicant[lastname]">
                Last Name
              </label>
              <input type="text" name="applicant[lastname]" className="form-control" ref="lastname" placeholder="Last Name" required />
            </div>
            <div className="form-group">
              <label className="sr-only" htmlFor="applicant[email]">
                Email Address
              </label>
              <input type="text" name="applicant[email]" className="form-control" ref="email" placeholder="Email Address" required/>
            </div>
            <SubmitButton value="Create Employee"/>
            <a href="#" onClick={this.props.hideForm}>Cancel</a>
          </fieldset>
        </form>
      </div>
    );
  }
});

$(->
  # Add questions to questionnaires

  if $('#subitems_holder #add_question').length > 0
    update_question = (el, data)->
      # replace question type class with new one
      $.each el.attr('class').split(/\s+/), (index, item)->
        if item.indexOf('question_type_') == 0
          el.removeClass(item)
      el.addClass('question_type_' + data.question_type)
      el.attr('id', 'question_' + data.id)
      el.find('.question_id').val(data.id)
      el.find('.question_name').html(data.name)
      result = ''
      if data.answers_attributes.length > 0
        el.addClass('has-answers')
        if data.fast_track == 1
          el.addClass('fast-track')
        else
          el.removeClass('fast-track')
        $.each data.answers_attributes, (i,e)->
          result = result + '<p class="answer">' + e.name + '</p>'
      else
        el.removeClass('has-answers')
        result = data.question_type_translated

      el.find('.question_name').attr('data-content', result)
      if data.section_id?
        new_section_el = $("#subitems_holder .current_questions.sec-#{data.section_id}")
        exists = new_section_el.children().index(el)
        if !exists?
          el.appendTo(new_section_el)
      $('#subitems_holder').trigger('subitems:updated')

    create_question = (data)->
      if data.section_id?
        questions_el = $("#subitems_holder .current_questions.sec-#{data.section_id}")
      else
        questions_el = $('#subitems_holder .current_questions')
      questions_el.append($('#subitems_holder #add_question').data('template'))
      update_question(questions_el.children('.form-group').last(), data)
    $('#subitems_holder #add_question').data('create_question', create_question)

    $(document).on 'show.bs.modal', '.modal.question', (e)->
      $(this).data 'after-create', (data)->
        create_question(data)
      $(this).data 'after-update', (data)->
        old_id = $('#modal').data('edited-question')
        $('#subitems_holder .current_questions .form-group').each ->
          if $(this).find('.question_id').val() == old_id
            update_question($(this), data)

    $(document).on 'click', '#add_library_question', (e)->
      q_id = parseInt($('#library_question_id').val())
      base_url = $(this).data('base-url')
      if q_id > 0
        $.ajax({
          url: base_url + q_id
          type: "GET"
          dataType: 'json'
          cache: false
          success: (data)->
            create_question(data)
            $('#library_question_id').val('').trigger('change')
        })
      false

    ## edit icon link for questions
    $(document).on 'click', '#subitems_holder .current_questions .edit_subitem', (e)->
      the_link = $(this)
      q_id = the_link.closest('.form-group').find('.question_id').val()
      the_modal = window.get_modal_markup()
      edit_url = $(this).data('base-url') + q_id + '/edit?library=' + $(this).data('library')
      the_modal.load edit_url, ->
        the_modal.find('.modal-title').text(the_link.data('title'))
        the_modal.attr('class', 'modal question_edit question')
        the_modal.data('edited-question', q_id)
        the_modal.modal()
      false

    ## remove icon link for questions
    $(document).on 'click', '#subitems_holder .current_questions .remove_question', (e)->
      the_q = $(this).closest('.form-group')
      base_url = $(this).data('base-url')
      if $(this).hasClass('destructive')
        $.ajax
          url: base_url + the_q.find('.question_id').val()
          type: "DELETE"
          dataType: 'json'
          cache: false
      the_q.remove()
      $('#subitems_holder').trigger('subitems:updated')
      false

    ## Tooltip-style previews when hovering over question data
    $('form.formtastic.has-questions').popover
      placement: 'top'
      selector: '#subitems_holder .current_questions .question_name'
      trigger: 'hover'
      html: true
      container: 'body'
      title: ->
        $(this).html()
)

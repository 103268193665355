$(->
  tinymce.PluginManager.add 'cpliquid', (editor, url)->
    each = editor.editorManager.each
    listName = 'Dynamic Fields'

    getData = ->
      $("##{editor.id}").data('context')
    return unless getData()?

    menuItems = (tags)->
      result = []
      each tags, (label, tag)->
        result.push
          text: label
          liquidKey: tag
          onclick: ()->
            editor.execCommand 'cpliquidInsertTag', this.settings.liquidKey
      result

    buildMenu = ->
      result = []
      each getData(), (data)->
        result.push
          text: data.label
          menu: menuItems(data.tags)
      result

    resetMenu = (ctrl)->
      ctrl.parent().settings.menu.items = menuData
      ctrl.parent().menu.remove()
      ctrl.parent().menu = null

    theMenuItem = null
    theSelectButton = null
    menuData = buildMenu()

    editor.addMenuItem 'cpliquidmenu',
      text: listName
      menu:
        type: 'menu'
        items: menuData
        onPostRender: (e)->
          theMenuItem = this

    editor.addButton 'cpliquidselect',
      type: 'menubutton'
      text: listName
      menu:
        type: 'menu'
        items: menuData
        onPostRender: (e)->
          theSelectButton = this

    editor.addCommand 'cpliquidInsertTag', (tag)->
      editor.execCommand 'mceInsertContent', false, tag

    editor.addCommand 'cpliquidReset', ->
      menuData = buildMenu()
      editor.menuItems.cpliquidmenu.menu.items = menuData
      resetMenu(theMenuItem) if theMenuItem
      editor.buttons.cpliquidselect.menu.items = menuData
      resetMenu(theSelectButton) if theSelectButton
)

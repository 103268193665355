# PRESCREEN LIBRARIES/QUESTIONNAIRES

$('body.manage.jobs #job_questionnaire_input select').change( ->
  if $(this).val()
    $('body.manage.jobs #job_prescreen_questions_input').hide()
  else
    $('body.manage.jobs #job_prescreen_questions_input').show()
).change()

$(document).on 'change', '#job_questionnaire_id', ->
  if $('#prescreen_questions').length > 0
    if $(this).val() == ''
      $('#prescreen_questions').collapse('show')
    else
      $('#prescreen_questions').collapse('hide')

adjust_select2 = ->
  $('#s2id_library_question_id span.select2-chosen').css 'width',
    $('#library_question_id').closest('.form-group').width() -
    $('#library_question_id').closest('.form-group').find('.input-group-btn').width() -
    26

$(window).on 'resize', adjust_select2
$('#subitems_holder').on 'shown.bs.collapse', adjust_select2
setTimeout adjust_select2, 2000

import _ from 'underscore'
{span, div, button, i, label, input} = React.DOM

randomId = ->
  Math.floor(Math.random() * 1000000000)

window.CommonItemsMixin =
  getInitialState: ->
    items: @processInitialItems(@props.initialItems)
    addItem: (item)=>
      item.temp_id = randomId()
      items = @state.items
      items.push(item)
      @setState items: _.sortBy items, (item)=>
        @props.renderName(item)
    removeItem: (item)=>
      items = @state.items
      if !@props.pureRemove && item.id?
        ind = _.findIndex items, (i)->
          item.temp_id == i.temp_id
        items[ind]._destroy = 1
      else
        items = _.reject items, (i)->
          item.temp_id == i.temp_id
      @setState items: items

  processInitialItems: (initialItems)->
    return [] unless initialItems? && initialItems.length > 0
    _.each initialItems, (item)->
      item.temp_id = randomId()
    _.sortBy initialItems, (item)=>
      @props.renderName(item)
  componentWillReceiveProps: (nextProps)->
    @setState
      items: @processInitialItems(nextProps.initialItems)

window.CommonSetComponent = React.createClass
  displayName: 'CommonSetComponent'
  getDefaultProps: ->
    limit: 0
    wrapper_class: 'string input stringish form-group'

  limitReached: ->
    return false if @props.limit < 1
    active = _.filter @props.items, (item)->
      item._destroy != 1
    active.length >= @props.limit

  emptyInputName: ->
    if @props.emptyTemplate?
      @props.emptyTemplate
    else
      @props.inputTemplate

  render: ->
    div { className: 'string_set_editor' },
      label {},
        @props.label
        if @props.info?
          React.createElement MoreInfo,
            info: @props.info
      if @props.items.length < 1
        input { name: @emptyInputName(), type: 'hidden' }
      div { className: 'strings' },
        _.map @props.items, (item)=>
          React.createElement(
            @props.itemComponent, _.extend {}, @props,
              key: "string-item-#{item.temp_id}"
              item: item
              onRemove: @props.removeItem
              renderName: @props.renderName
          )
      unless @limitReached()
        div { className: 'form-inline' },
          div { className: @props.wrapper_class },
            @props.addItemEditor

import _ from 'underscore'
{span, div, button, i, input} = React.DOM

window.StringSetComponent = React.createClass
  displayName: 'StringSetComponent'
  mixins: [CommonItemsMixin]

  getDefaultProps: ->
    pureRemove: false
    renderName: (item)->
      item.name

  captureEnter: (e)->
    if (e.charCode == 13)
      @addItem(e)

  addItem: (e)->
    e.preventDefault()
    el = $(ReactDOM.findDOMNode(@refs.newString))
    return if el.val() == ''
    item = _.extend({ name: el.val() }, @props.defaultAttrs)
    @state.addItem(item)
    el.val('')

  render: ->
    React.createElement CommonSetComponent, _.extend {}, @props,
      removeItem: @state.removeItem
      items: @state.items
      itemComponent: StringSetItemComponent
      addItemEditor: div { className: 'input-group' },
        input {
          ref: 'newString',
          type: 'text',
          className: 'form-control',
          placeholder: @props.placeholder,
          onKeyPress: @captureEnter
        }
        span { className: 'input-group-btn' },
          button { className: 'btn btn-action', type: 'button', onClick: @addItem },
            i { className: 'fa fa-plus' }, ''

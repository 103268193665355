module.exports = (elm) ->
  sig = require('cplook/assets/javascripts/cplook/elm/signature.coffee')(elm)
  $ ->
    $(document).on 'show.bs.modal', '.modal', ->
      return unless $('.signature_pad_app:empty').length > 0

      cmdHandlerCreator = ->
        signature_modal = this
        {
          'form-ready': (data) ->
            toggle_accept = (accept_element, enabled)->
              if enabled == true
                $(accept_element).removeClass('disabled')
              else if enabled == false
                $(accept_element).addClass('disabled')

            submit_form = $(signature_modal).find('.submit-form')
            return unless submit_form.length > 0
            if data == 'false' || !(data?)
              toggle_accept(submit_form, false)
            else if data == 'true'
              toggle_accept(submit_form, true)
        }

      cmdHandlerCreator.bind($(this)).call()['form-ready'].call('false')
      sig.initialize_signatures(cmdHandlerCreator.bind($(this)).call())

import _ from 'underscore'

{span, div, button, i, select, option} = React.DOM

window.ObjectSetComponent = React.createClass
  displayName: 'ObjectSetComponent'
  mixins: [CommonItemsMixin]

  componentDidMount: ->
    return unless @props.autocomplete?
    el = $(ReactDOM.findDOMNode(@refs.newObject))
    el.attr("data-tags", 1)
    if el.closest('.modal').length > 0
      window.cp_init_autocomplete($(el), { dropdownParent: el.closest('.modal-body') })
    else
      window.cp_init_autocomplete($(el))

  getDefaultProps: ->
    pureRemove: true
    renderName: (item)->
      item.name
    filter: (item)->
      true
    wrapper_class: 'select input optional form-group'

  getInitialState: ->
    availableItems: @processAvailableItems(@props.availableItems)

  processAvailableItems: (availableItems)->
    return [] unless availableItems? && availableItems.length > 0
    items = _.filter availableItems, (item)=>
      @props.filter(item)
    _.sortBy items, (item)=>
      @props.renderName(item)

  componentWillReceiveProps: (nextProps)->
    @setState
      availableItems: @processAvailableItems(nextProps.availableItems)

  addItem: (e)->
    e.preventDefault()
    el = $(ReactDOM.findDOMNode(@refs.newObject))
    items = @state.availableItems
    item = _.findWhere(items, { id: parseInt(el.val()) })
    if item == undefined && @props.autocomplete?
      item = {id: null, name: el.val()}
    @state.addItem(item)
    @setState
      availableItems: _.reject items, (it)->
        it.id == item.id
    el.val('')
    if @props.autocomplete?
      $(el).closest('.input-group').find('.select2-selection__rendered').html('')

  removeItem: (item)->
    @state.removeItem(item)
    items = @state.availableItems
    items.push(item)
    @setState availableItems: _.sortBy items, (item)=>
      @props.renderName(item)

  availableItems: ->
    usedIds = _.pluck(@state.items, 'id')
    _.reject @state.availableItems, (item)->
      _.contains(usedIds, item.id)

  render: ->
    React.createElement CommonSetComponent, _.extend {}, @props,
      items: @state.items
      itemComponent: ObjectSetItemComponent
      removeItem: @removeItem
      addItemEditor: div { className: 'input-group' },
        select { ref: 'newObject', className: 'form-control' },
          option { value: '' }, @props.addLabel
          _.map @availableItems(), (item)=>
            option
              key: "avail-item-#{@props.parentId}-#{item.id}"
              value: item.id
            , @props.renderName(item)
        span { className: 'input-group-btn' },
          button { className: 'btn btn-action', type: 'button', onClick: @addItem },
            i { className: 'fa fa-plus' }, ''

# DROPBOX & GOOGLE DRIVE FUNCTIONALITY

# NOTE: Although the Dropbox and Google Drive links are only used on the microsites at present,
# I am placing this file in shared since its functionality is globally reusable.

$(->
  origin = $('meta[name="transmit-origin"]').attr('content')

  localDone = (key, result)->
    $(".cloud-storage-result-#{key} input").val(result)
    fa = $(".cloud-storage-result-#{key}").closest('.file-area')
    fa.trigger 'done', { name: fa.data('name'), value: result }

  $(document).on 'click', '.cloud-storage-choosers .dropbox-chooser a', ->
    key = $(this).data('key')
    local = $(this).parents('.service.cloud-storage.local').length > 0
    Dropbox.choose
      success: (files)->
        result = "dropbox:" + files[0].link
        if local
          localDone(key, result)
        else
          parent.postMessage
            service: 'cloud_storage'
            key: key
            result: result
          , origin
      linkType: "direct"
      multiselect: false
      extensions: [
        '.pdf', '.doc', '.docx', '.ppt', '.pps', '.pptx',
        '.ppsx', '.odt', '.oth', '.odm', '.odp', '.odf', '.oxt',
        '.rtf', '.htm', '.html', '.text', '.txt'
      ]
    false

  $(window).on 'message', (ev)->
    event = ev.originalEvent
    if event.origin == origin && event.data? && event.data.service == 'cloud_storage'
      localDone(event.data.key, event.data.result)

  google_button = $('.cloud-storage-choosers .google-chooser')
  return unless google_button.length > 0

  pickerApiLoaded = false
  oauthToken = null

  pickerCallback = (data)->
    if data[google.picker.Response.ACTION] == google.picker.Action.PICKED
      doc = data[google.picker.Response.DOCUMENTS][0]
      window.opener.postMessage
        service: 'cloud_storage'
        key: google_button.data('key')
        result: "google:#{oauthToken}:#{doc.id}:/#{doc.name}"
      , origin
      window.close()

  createPicker = ->
    if pickerApiLoaded && oauthToken
      picker = new google.picker.PickerBuilder().
                 addView(google.picker.ViewId.DOCUMENTS).
                 addView(google.picker.ViewId.PDFS).
                 setOAuthToken(oauthToken).
                 setDeveloperKey(google_button.data('apikey')).
                 setCallback(pickerCallback).
                 build()
      picker.setVisible(true)

  handleAuthResult = (authResult)->
    if authResult && !authResult.error
      oauthToken = authResult.access_token
      createPicker()

  onAuthApiLoad = ()->
    window.gapi.auth.authorize
      'client_id': google_button.data('client')
      'scope': ['https://www.googleapis.com/auth/drive.readonly']
      'immediate': false
    , handleAuthResult

  onPickerApiLoad = ()->
    pickerApiLoaded = true
    createPicker()

  window.onGoogleApiLoad = ->
    gapi.load('auth', {'callback': onAuthApiLoad})
    gapi.load('picker', {'callback': onPickerApiLoad})

  $.ajax
    url: "https://apis.google.com/js/api.js?onload=onGoogleApiLoad"
    dataType: 'script'
    async: true
)

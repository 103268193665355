$(->
  if $('.password-with-rules').length > 0
    $('.password-with-rules').each ->
      node = $(this)
      node.on 'elm_init', (_, app) ->
        app.ports.passwordOut.subscribe (data) ->
          if data.tag == 'after_init'
            node.find('input').popover({
                trigger: 'focus',
                placement: 'bottom',
                title: 'Password must include',
                html: true,
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title" style="background-color:#015d96; color:#ffffff;font-size:14px;"></h3><div class="popover-content"></div></div>'
                content: "<ul style='padding-left:10px;background-color=#f7f7f7;color:#111'><li>At least 8 characters</li>
                <li>Uppercase</li>
                <li>Lowercase</li>
                <li>Symbol</li>
                <li>Number</li></ul>",
            });

)

import _ from 'underscore'

FluxBoneMixin = (propName) ->
  componentDidMount: ->
    @props[propName].on "all", =>
      @forceUpdate()
    , @

  componentWillUnmount: ->
    @props[propName].off "all", =>
      @forceUpdate()
    , @

window.FluxBoneMixin = FluxBoneMixin

window.ItemStore =
  createClass: (list, single)->
    _.extend {}, ItemStore,
      listUrl: list
      singleUrl: single
      listeners: {}
  renderName: (item)->
    item.name
  loadItems: (cb)->
    $.get @listUrl, (data)=>
      @items = _.sortBy data, (item)=>
        @renderName(item)
      cb() if cb?
      @trigger('change')
  ajaxAttrs: (element, attrs)->
    if tinyMCE?
      tinyMCE.editors.forEach (editor)->
        editor.save()
    if typeof FormData == 'undefined'
      _.extend {}, attrs,
        dataType: 'json'
        data: element.find(':input').serialize()
    else
      data = new FormData()
      _.each element.serializeArray(), (item)->
        data.append item.name, item.value
      element.find('input[type="file"]').each ->
        if $(this)[0].files[0]?
          data.append $(this).attr('name'), $(this)[0].files[0]
      _.extend {}, attrs,
        dataType: 'json'
        data: data
        processData: false
        contentType: false

  addItem: (element, onSuccess, onError, onComplete)->
    $.ajax @ajaxAttrs element,
      url: @listUrl
      method: 'POST'
      success: =>
        @loadItems =>
          sitems = _.sortBy @items, (item)->
            item.created_at
          @trigger 'new', _.last(sitems).id
        onSuccess()
      error: onError
      complete: onComplete
  updateItem: (id, element, onSuccess, onError, onComplete)->
    $.ajax @ajaxAttrs element,
      url: @singleUrl(id)
      method: 'PATCH'
      success: =>
        @loadItems ->
          @trigger 'update', id
        onSuccess()
      error: onError
      complete: onComplete
  getItems: ->
    @items
  addListener: (name, cb)->
    @listeners[name] = [] unless @listeners[name]?
    if _.indexOf(@listeners[name], cb) == -1
      @listeners[name].push cb
  removeListener: (name, cb)->
    @listeners[name] = _.without(@listeners[name], cb)
  trigger: (name, data)->
    return unless @listeners[name]?
    @listeners[name].forEach (cb)->
      cb(data)

require("./components/ats_backbone.coffee")
require("./components/modal.coffee")
require("./components/utils.js")
require("./components/buttons.jsx")
require("./components/errors.jsx")

require("./components/string_set/common_set.coffee")
require("./components/string_set/object_set_item.coffee")
require("./components/string_set/object_set.coffee")
require("./components/string_set/string_set_item.coffee")
require("./components/string_set/string_set.coffee")
require("./components/string_set/employee_set.coffee")

require("./components/evaluations/employee.jsx")
require("./components/evaluations/new_employee_form.jsx")

require("./components/job_edit/department_chooser.coffee")
require("./components/job_edit/brand_chooser.coffee")
require("./components/job_edit/district_chooser.coffee")
require("./components/job_edit/location_chooser.coffee")
require("./components/job_edit/region_chooser.coffee")
require("./components/job_edit/job_categories.coffee")
require("./components/job_edit/benchmark_employees.coffee")
require("./components/job_edit/compensation.coffee")

require("./components/editors/item_editor.coffee")
require("./components/editors/item_chooser.coffee")
require("./components/editors/department_editor.coffee")
require("./components/editors/brand_editor.coffee")
require("./components/editors/location_editor.coffee")
require("./components/editors/user_editor.coffee")
require("./components/editors/region_editor.coffee")

import AutoNumeric from 'autonumeric';

updateFields = (e) ->
  e.preventDefault
  if e.target.value == 'fixed' 
    maxInput.set('');
    minField.labels[0].innerHTML = "Amount ($)"
    maxFieldElement.classList.add("hidden")
  else
    maxFieldElement.classList.remove("hidden")
    minField.labels[0].innerHTML = "Min ($)"

minField = document.getElementById("job_minimum_compensation")

if minField != null

  maxField = document.getElementById("job_maximum_compensation")
  maxFieldElement = document.getElementById("maxFieldElement")
  jobPayStyle = document.getElementById("job_pay_style")

  parsedMinimum = parseFloat(minField.value/100).toFixed(2);
  parsedMaximum = parseFloat(maxField.value/100).toFixed(2);

  minInput = new AutoNumeric(minField, ['floatPos', { allowDecimalPadding: true, decimalPlacesShownOnBlur: 2 } ]);
  maxInput = new AutoNumeric(maxField, ['floatPos', { allowDecimalPadding: true, decimalPlacesShownOnBlur: 2 } ]);

  if parsedMinimum > 0 
    minInput.set(parsedMinimum)

  if parsedMaximum > 0
    maxInput.set(parsedMaximum)

  if jobPayStyle != null
    jobPayStyle.addEventListener 'change', updateFields

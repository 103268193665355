$(->
  # Set on "Click to copy link" anchors; displays value to be copied in a text field below
  # Presently used on Team > Assessment Link/Url modal; also referenced in (stale?) Evaluation/Performance Review modal code.
  # Placing this in global because it is reusable & generic
  $(document).on 'click', 'a.show_toggle', (ev)->
    ev.preventDefault()
    if $(this).data('orig-text')
      $(this).closest('div').find('.prompt').html($(this).data('orig-text'))
      $(this).data('orig-text', null)
    else if $(this).data('alternate')
      $(this).data('orig-text', $(this).closest('div').find('.prompt').html())
      $(this).closest('div').find('.prompt').html($(this).data('alternate'))

    $(this).closest('div').find('.toggle').toggle()
    $(this).closest('div').find('.toggle').find('input').first().focus()
    return false
)

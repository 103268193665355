import cplook from 'cplook'

$(window).on 'resize', ->
  handleDisable()

handleDisable = ->
  mobile = $(window).width() < cplook.mobilePivot

  $('.mobile-show fieldset').attr('disabled', not(mobile))
  $('.mobile-hide fieldset').attr('disabled', mobile)

$(->
  handleDisable()
)

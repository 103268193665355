# cocoon: https://github.com/nathanvda/cocoon
# cocoon_add_association & cocoon_remove_association are defined in cplook

$(->
  $('.cocoon-limit').each ->
    the_wrapper = $(this)
    the_limit = parseInt($(this).data('limit'))
    len = the_wrapper.find('.nested-fields').length
    if len >= the_limit
      the_wrapper.find('a.add_fields').hide()
    the_wrapper.find('a.add_fields').on 'click', ->
      len = the_wrapper.find('.nested-fields').length + 1
      if len >= the_limit
        $(this).hide()
    the_wrapper.on 'click', 'a.remove_fields', ->
      len = the_wrapper.find('.nested-fields').length - 1
      if len < the_limit
        the_wrapper.find('a.add_fields').show()
)

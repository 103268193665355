import _ from 'underscore'
window.DepartmentChooser = React.createClass
  displayName: 'DepartmentChooser'
  getDefaultProps: ->
    store: ItemStore.createClass '/manage/departments.json', (id)->
      "/manage/departments/#{id}.json"
    userStore: ItemStore.createClass '/manage/users.json', (id)->
      "/manage/users/#{id}.json"
  componentDidMount: ->
    @props.userStore.loadItems()
  render: ->
    React.createElement ItemChooser,
      _.extend {}, @props,
        blankItem: 'None Selected'
        editor: DepartmentEditor
        label: 'Department'

require("./cloud_storage.coffee")
require("./color_picker.coffee")
require("./job_edit.coffee")
require("./locale_switchable.coffee")
require("./questionnaires.coffee")
require("./table_sort.coffee")
require("./tinymce_liquid.coffee")
require('./upload_candidate.coffee')

// Scripts that export API and should be required directly where used:
//  require("./clipboard_builder.coffee")
//  require("./inline_pdf.coffee")

import _ from 'underscore'
{ div, form, label, span, fieldset, input, select, option } = React.DOM

window.BrandEditor = React.createClass
  displayName: 'BrandEditor'
  getDefaultProps: ->
    addOnly: false
  beforeSubmit: ->
    tmce = _.findWhere(tinyMCE.editors, { id: 'as_brand_description' })
    tmce.save() if tmce?
  render: ->
    React.createElement ItemEditor, _.extend {}, @props,
      thing: 'brand'
      name: 'Brand'
      itemForm: BrandModalBody
      size: 'lg'
      beforeSubmit: @beforeSubmit

BrandModalBody = React.createClass
  displayName: 'BrandModalBody'
  render: ->
    fieldset { className: 'inputs' },
      div { className: 'row' },
        div { className: 'col-md-8' },
          React.createElement ItemString,
            item: @props.item
            thing: 'brand'
            name: 'name'
            label: 'Brand Name'
            required: true
        div { className: 'col-md-4' },
          React.createElement ItemString,
            item: @props.item
            thing: 'brand'
            name: 'subdomain'
            label: 'Subdomain'
            required: true
      React.createElement AccountDescription,
        item: @props.item
        label: 'Brand Description'
        thing: 'brand'
      div { className: 'row' },
        div { className: 'col-md-6' },
          if typeof FormData == 'undefined'
            p {}, 'Please visit the Company page to add a logo and/or photos to this brand.'
          else
            div {},
              React.createElement CompanyLogo,
                thing: 'brand'
                item: @props.item
                label: 'Brand Logo'
              React.createElement CompanyPhotos,
                thing: 'brand'
                item: @props.item
                label: 'Brand Photos'
        div { className: 'col-md-6' },
          React.createElement CompanyLinks,
            thing: 'brand'
            item: @props.item
      React.createElement AccountColors,
        thing: 'brand'
        item: @props.item

# TODO: This was lifted directly from pdf_form gem. Short-term fix: move this
#       and all other common react stuff to cplook. Long-term fix: start using
#       Bootstrap-React project.

import _ from 'underscore'

{div, span, button, h4, p, i} = React.DOM

window.CPModalButton = React.createClass
  handleClose: (e)->
    $(ReactDOM.findDOMNode(@)).closest('.modal').modal('hide')
  render: ->
    attrs =
      type: 'button'
      className: "btn btn-#{@props.buttonClass}"
    if @props.dismiss
      attrs['onClick'] = @handleClose
    button attrs, @props.name

window.CPModalTrigger = React.createClass
  getDefaultProps: ->
    size: ''
  handleClick: (e)->
    $(ReactDOM.findDOMNode(@refs.payload)).modal()
  render: ->
    span { onClick: @handleClick },
      @props.trigger
      React.createElement CPModal,
        ref: 'payload'
        title: @props.title
        body: @props.body
        footer: @props.footer
        hiddenCallback: @props.hiddenCallback
        size: @props.size

window.CPModal = React.createClass
  componentDidMount: ->
    $(ReactDOM.findDOMNode(@)).modal
      backdrop: 'static'
      keyboard: false
      show: false
    $(ReactDOM.findDOMNode(@)).on 'hidden.bs.modal', @props.hiddenCallback
  componentWillUnmount: ->
    $(ReactDOM.findDOMNode(@)).off 'hidden.bs.modal'
  handleClick: (e)->
    e.stopPropagation()
  handleClose: (e)->
    $(ReactDOM.findDOMNode(@)).modal('hide')
  sizeClass: ->
    if @props.size?
      "modal-#{@props.size}"
    else
      ''
  render: ->
    div {
      onClick: @handleClick,
      className: 'modal',
      role: 'dialog',
      'aria-hidden': true
     },
      div { className: "modal-dialog #{@sizeClass()}" },
        div { className: 'modal-content' },
          div { className: 'modal-header' },
            button {
              type: 'button',
              className: 'close',
              onClick: @handleClose
            }, '×'
            h4 { className: 'modal-title' }, @props.title
          div { className: 'modal-body' },
            @props.body
          div { className: 'modal-footer' },
            @props.footer

window.CPSimpleModalFooter = React.createClass
  displayName: 'SimpleModalFooter'
  render: ->
    div {},
      React.createElement CPModalButton,
        buttonClass: 'primary'
        dismiss: true
        name: @props.name

window.CPBrowserFormValidation = (parent)->
  valid = true
  return true unless (typeof document.createElement( 'input' ).checkValidity == 'function')
  nested = parent.find('.modal-body :input')
  inputs = _.reject parent.find(':input'), (el)->
    _.find nested, (test)->
      $(test).is($(el))
  inputs.forEach (el)->
    el.checkValidity()
    unless el.validity.valid
      valid = false
      $(el).closest('.form-group').addClass('has-error')
      $(el).on 'focus', ->
        $(el).closest('.form-group').removeClass('has-error')
  valid

window.CPFormModalFooter = React.createClass
  displayName: 'FormModalFooter'
  onClick: (e)->
    valid = CPBrowserFormValidation($(e.target).closest('.modal-content').find('.modal-body'))
    if valid
      $(e.target).closest('.modal').modal('hide')
  render: ->
    div {},
      button {
        type: 'button'
        className: 'btn btn-primary'
        onClick: @onClick
      }, @props.name

window.CPManagedModalFooter = React.createClass
  displayName: 'ManagedModalFooter'
  render: ->
    div {},
      button {
        type: 'button'
        className: 'btn btn-primary'
        onClick: @props.onClick
      },
        @props.name
        span { className: 'indicator' },
          i { className: 'fa fa-refresh fa-spin' }

window.CPConfirmModal = React.createClass
  displayName: 'CPConfirmModal'
  bodyContent: ->
    if @props.message?
      @props.message
    else
      'Are you sure?'
  render: ->
    React.createElement CPModalTrigger,
      trigger: @props.trigger
      title: 'Confirm'
      body: p {}, @bodyContent()
      footer: React.createElement(ConfirmModalFooter, onConfirm: @props.onConfirm)
      size: 'sm'

window.ConfirmModalFooter = React.createClass
  displayName: 'ConfirmModalFooter'
  handleClose: (e)->
    $(ReactDOM.findDOMNode(@)).closest('.modal').modal('hide')
    @props.onConfirm()
  render: ->
    div {},
      React.createElement CPModalButton,
        buttonClass: 'default'
        dismiss: true
        name: 'Cancel'
      button {
        type: 'button'
        className: "btn btn-#{@props.buttonClass}"
        onClick: @props.handleClose
      }, 'Confirm'


import { Controller } from "@hotwired/stimulus";

export default class extends Controller{
  static targets = ["modal"];

  connect(){
    if (this._isOnTargetPage() && this._isFirstVisit()){
      this.showModal();
    }
  }

  showModal() {
    $('#aggregatorModal').modal({
      backdrop: "static",
      keyboard: false,
      show: true
    });
    this.modalTarget.style.display = "block";
  }

  dismissModal() {
    $('#aggregatorModal').modal('hide');
    this.modalTarget.style.display = "none";
    this._setDismissCookie();
  }

  _isFirstVisit(){
    return !document.cookie.split(';').some((item) => item.trim().startsWith('modalDismissed='));
  }

  _setDismissCookie(){
    document.cookie = "modalDismissed=true; path=/; max-age=7776000; SameSite=Lax";
  }

  _isOnTargetPage(){
     return document.querySelector('.aspirant-portal') !== null;
  }
}


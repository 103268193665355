$(->
  # ADMIN > JOB AGGREGATORS > NEW/EDIT
  # MANAGE > COMPANY > CUSTOMIZE CAREERS PAGES/WIDGET > CAREERS WIDGET > TABLE

  ## Enables/disables "Sort" radio buttons when "Enabled" checkboxes are checked/unchecked
  $('#table td.show input[type="checkbox"]').change(->
    $(this).closest('tr').find('td.sort input').prop('disabled', !$(this).prop('checked'))
    if $('#table td.sort input:checked').length < 1 || $('#table td.sort input:checked').prop('disabled')
      $('#table td.sort:first input[type="radio"]').prop('checked', true)
  ).change()
  
)

window.CPErrors = React.createClass({
  render: function() {
    if (this.props.errors) {
      var errors = this.props.errors.map(function(error){
        return <CPError error={error} />
      });
      return (
        <div className="alert alert-danger">
          The following errors prevented the form from saving
          <ul>
            {errors}
          </ul>
        </div>
      )
    }
    else {
      return null;
    }
  }
});

window.CPError = React.createClass({
  render: function() {
    return (
      <li>
      {this.props.error}
      </li>
    );
  }
});

import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["steps"];

  connect() {
    this.adjust_step_widths();
    this.initialize_sortable();
    this.disable_cocoon_remove_handler();
  }

  adjust_step_widths() {
    window.addEventListener("resize", this.update_wrapper_width);
    this.update_wrapper_width();

    let w = 0;
    let h = 0;
    for (const step of this.stepsTarget.querySelectorAll(".nested-step")) {
      w = w + $(step).width() + 10;
      h = Math.max(h, $(step).height());
    }
    $("#hiring_steps .nested-step > fieldset").css({
      height: `${h}px`,
    });
    $(this.stepsTarget).css({ width: `${w}px` });
  }

  update_wrapper_width() {
    $(this.element).css({
      width: $(this.element).closest("form").width(),
    });
  }

  initialize_sortable() {
    Sortable.create(this.stepsTarget, {
      handle: ".handle",
      draggable: ".nested-step",
      filter: ".hire-step",
      scroll: this.element,
      scrollSensitivity: 40,
      onMove: (event) => {
        return !event.related.classList.contains("hire-step");
      },
      onEnd: () => {
        let pos = 0;
        for (const posInput of this.stepsTarget.querySelectorAll(
          'input[data-field="position"]'
        )) {
          pos = pos + 1;
          $(posInput).val(pos);
        }
      },
    });
  }

  disable_cocoon_remove_handler() {
    $(this.stepsTarget).on(
      "cocoon:before-remove",
      function (event, removedItem) {
        if ($(removedItem).find(".remove_step[data-action]").length > 0) {
          event.preventDefault();
        }
      }
    );
  }
}

import _ from 'underscore'
{ div, label, span, fieldset, input, select, option, i, a, abbr } = React.DOM

window.ItemEditor = React.createClass
  displayName: 'ItemEditor'
  getDefaultProps: ->
    renderName: (item)->
      item.name
    size: ''
  getInitialState: ->
    items: []
  onStoreChange: ->
    @setState
      items: @props.store.getItems()
  componentWillMount: ->
    @props.store.renderName = @props.renderName
    @props.store.addListener 'change', @onStoreChange
    @props.store.loadItems()
  componentWillUnmount: ->
    @props.store.removeListener 'change', @onStoreChange
  newItemCallback: (e)->
    e.stopPropagation()
    @props.beforeSubmit() if @props.beforeSubmit?
    if CPBrowserFormValidation($(e.target).closest('.modal-content').children('.modal-body'))
      btn = $(e.target)
      modal = btn.closest('.modal')
      frm = modal.find('fieldset.form')
      btn.addClass('waiting')
      @props.store.addItem frm,
        ->
          frm.find(':input').each (i, el)->
            switch el.type
              when 'password', 'text', 'textarea', 'file', 'select-one', 'select-multiple'
                $(el).val('')
              when 'checkbox', 'radio'
                el.checked = false
          modal.modal('hide')
      ,
        @handleError.bind(@, frm)
      ,
        ->
          btn.removeClass('waiting')
  editItemCallback: (e)->
    e.stopPropagation()
    @props.beforeSubmit() if @props.beforeSubmit?
    if CPBrowserFormValidation($(e.target).closest('.modal-content').children('.modal-body'))
      itemId = $(e.currentTarget).find('fieldset.form').data('id')
      btn = $(e.target)
      modal = btn.closest('.modal')
      frm = modal.find('fieldset.form')
      btn.addClass('waiting')
      @props.store.updateItem frm.data('id'), frm,
        ->
          frm.trigger('reset')
          modal.modal('hide')
      ,
        @handleError.bind(@, frm)
      ,
        ->
          btn.removeClass('waiting')
  handleError: (frm, jqxhr)->
    return unless jqxhr?
    if jqxhr.responseJSON?
      errData = jqxhr.responseJSON.errors
      errData = jqxhr.responseJSON unless errData?
      _.each errData, (errors, field)->
        wrapper = frm.find(".input.form-group[data-field='#{field}']")
        wrapper.addClass('has-error')
        errorText = wrapper.find('.help-block.error-text')
        errorText.data('orig-error', errorText.html())
        errorText.text(errors[0])
        wrapper.find('.form-control').on 'focus', ->
          wrapper.removeClass('has-error')
          errorText.text(errorText.data('orig-error'))
  canAdd: ()->
    return true unless @props.max? && parseInt(@props.max) > 0
    @state.items.length < parseInt(@props.max)
  render: ->
    div { className: "item_editor #{@props.thing}_editor" },
      unless @props.addOnly || @state.items.length < 1
        div { className: 'items' },
          label {}, @props.listLabel
          _.map @state.items, (item)=>
            React.createElement SingleItem,
              _.extend {}, @props,
                key: "#{@props.thing}-#{item.id}"
                item: item
                editItemCallback: @editItemCallback
                modalProps: _.extend {}, @props,
                  item: item
      if @canAdd()
        div { className: 'add-item' },
          React.createElement CPModalTrigger,
            trigger: a({ className: 'btn btn-action btn-xs' }, "Add #{@props.name}")
            title: "Adding New #{@props.name}"
            body: React.createElement FormModal,
              _.extend {}, @props,
                item: {}
            footer: React.createElement CPManagedModalFooter,
              name: 'Add'
              onClick: @newItemCallback
            hiddenCallback: ->
              true
            size: @props.size

FormModal = React.createClass
  displayName: 'FormModal'
  render: ->
    fieldset { className: "form formtastic #{@props.thing}-form", 'data-id': @props.item.id },
      React.createElement @props.itemForm, @props

SingleItem = React.createClass
  displayName: 'SingleItem'
  render: ->
    span { className: "item #{@props.thing} btn-xs" },
      span { className: 'name' }, @props.renderName(@props.item)
      React.createElement CPModalTrigger,
        trigger: a({ className: 'trigger' }, i({ className: 'fa fa-pencil' }, ''))
        title: "Editing #{@props.renderName(@props.item)}"
        body: React.createElement FormModal, @props.modalProps
        footer: React.createElement CPManagedModalFooter,
          name: 'Save'
          onClick: @props.editItemCallback
        hiddenCallback: ->
          true
        size: @props.size

window.MoreInfo = React.createClass
  displayName: 'MoreInfo'
  render: ->
    span {},
      ' '
      span { className: 'has-tooltip help-icon', title: @props.info },
        i { className: 'fa fa-question-circle fa-md' }, ''

window.ItemInput = React.createClass
  displayName: 'ItemInput'
  getInitialState: ->
    value: @props.item[@props.name]
  componentWillReceiveProps: (nextProps)->
    return unless nextProps.item? && nextProps.name?
    @setState
      value: nextProps.item[nextProps.name]
  onChange: (e)->
    @setState
      value: $(e.currentTarget).val()
  render: ->
    inputAttrs =
      id: "if_#{@props.thing}_#{@props.name}"
      className: 'form-control'
      name: "#{@props.thing}[#{@props.name}]"
      type: @props.type
      value: @state.value
      onChange: @onChange
      placeholder: @props.placeholder
    wrapperClass = 'string input stringish form-group item-input'
    errorText = @props.errorText
    if @props.required?
      inputAttrs.required = 'required'
      wrapperClass = wrapperClass + ' required'
      unless errorText?
        errorText = "can't be blank"
    div { className: wrapperClass, 'data-field': @props.name },
      label
        className: 'control-label'
        htmlFor: "if_#{@props.thing}_#{@props.name}"
      ,
        @props.label
        if @props.required?
          abbr { title: 'required' }, '*'
        if @props.info?
          React.createElement MoreInfo,
            info: @props.info
      span { className: 'form-wrapper' },
        input inputAttrs
        span { className: 'error-text help-block' }, errorText
      span { className: 'hint help-block' }, @props.hint

window.ItemString = React.createClass
  displayName: 'ItemString'
  render: ->
    React.createElement ItemInput, _.extend {}, @props,
      type: 'text'

window.ItemEmail = React.createClass
  displayName: 'ItemEmail'
  render: ->
    React.createElement ItemInput, _.extend {}, @props,
      type: 'email'
      errorText: 'must be a valid email'

window.ItemUrl = React.createClass
  displayName: 'ItemUrl'
  render: ->
    React.createElement ItemInput, _.extend {}, @props,
      type: 'text'
      errorText: 'must look like a web address'

window.ItemSelect = React.createClass
  displayName: 'ItemSelect'
  getInitialState: ->
    value: @props.item[@props.name]
  componentWillReceiveProps: (nextProps)->
    return unless nextProps.item? && nextProps.name?
    @setState
      value: nextProps.item[nextProps.name]
  onChange: (e)->
    @setState
      value: $(e.currentTarget).val()
  render: ->
    div { className: 'select input optional form-group item-input', 'data-field': @props.name },
      label { className: 'control-label', htmlFor: "if_#{@props.thing}_#{@props.name}" },
        @props.label
        if @props.info?
          React.createElement MoreInfo,
            info: @props.info
      span { className: 'form-wrapper' },
        select
          id: "if_#{@props.thing}_#{@props.name}"
          className: 'form-control'
          name: "#{@props.thing}[#{@props.name}]"
          value: @state.value
          onChange: @onChange
        ,
          _.map @props.options, (opt)=>
            option { value: opt[0], key: "#{@props.name}-#{opt[0]}" }, opt[1]

{a, i, input, span} = React.DOM

window.ObjectSetItemComponent = React.createClass
  displayName: 'ObjectSetItemComponent'
  handleRemove: (event)->
    event.preventDefault()
    @props.onRemove(@props.item)

  renderItemValue: (item)->
    if item.id
      item.id
    else
      item.name

  render: ->
    if @props.item._destroy == 1
      span {}, ''
    else
      a { href: '#', className: 'btn btn-xs btn-default', onClick: @handleRemove },
        @props.renderName(@props.item)
        i { className: 'fa fa-times' }
        input { name: @props.inputTemplate, value: @renderItemValue(@props.item), type: 'hidden' }

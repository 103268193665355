"use strict";

var Utils = {
  safeDate: function(dateTime) {
    var date = new Date(dateTime);
    if(isNaN(date.getFullYear())) {
      date = new Date();
    }
    return date;
  },
  cleanDateTime: function(dateTime) {
    var date = Utils.safeDate(dateTime);
    return (
      (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
    );
  },
  datePickerFormat: function(date) {
    if (date === null || date === '') {
      return '';
    }
    var d = Utils.safeDate(date);
    return (
      d.getFullYear() + '-' +  (d.getMonth() + 1) + '-' + d.getDate()
    );
  },
  shortDate: function(date) {
    if (date === null || date === '') {
      return '';
    }
    var d = Utils.safeDate(date);
    return (
      (d.getMonth() + 1) + '/' + d.getDate()
    );
  }
};
module.exports = Utils;

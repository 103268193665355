window.Employee = React.createClass({
  getInitialState: function() {
    return {
      editing: false
    }
  },
  componentDidMount: function() {
    this.setState({editing: this.props.editing});
  },
  editEmployee: function(e) {
    e.preventDefault();
    this.setState({editing: true});
  },
  updateEmployee: function(e) {
    e.preventDefault();
    var firstName = ReactDOM.findDOMNode(this.refs.firstName).value;
    var lastName = ReactDOM.findDOMNode(this.refs.lastName).value;
    var email = ReactDOM.findDOMNode(this.refs.email).value;

    this.props.updateEmployee(
      this.props.employee.id,
      {
        id: this.props.employee.applicant_id,
        firstname: firstName,
        lastname: lastName,
        email: email
      }
    );
    this.props.reloadEmployees();
    this.setState({editing: false});
  },
  handleDelete: function(e) {
    e.preventDefault();
    this.props.deleteEmployee(this.props.employee.id);
  },
  render: function() {
    var employee = this.props.employee.applicant;
    if (this.state.editing) {
      return (
        <tr>
          <td className="update-employee">
            <a href="#" className="btn btn-action" onClick={this.updateEmployee}>Save</a>
          </td>
          <td className="first-name">
            <label className="sr-only" htmlFor="employee[firstname]">
              First Name
            </label>
            <input type="text" className="form-control" name="employee[firstname]" ref="firstName" defaultValue={employee.firstname}></input>
          </td>
          <td className="last-name">
            <label className="sr-only" htmlFor="employee[lastname]">
              Last Name
            </label>
            <input type="text" className="form-control" name="employee[lastname]" ref="lastName" defaultValue={employee.lastname}></input>
          </td>
          <td className="email">
            <label className="sr-only" htmlFor="employee[email]">
              Email Address
            </label>
            <input type="text" className="form-control" name="employee[email]" ref="email" defaultValue={employee.email}></input>
          </td>
        </tr>
      );
    }
    else {
      return (
        <tr>
        <td className="edit" onClick={this.editEmployee}><a href="#">Edit</a></td>
        <td className="first-name">{employee.firstname}</td>
        <td className="last-name">{employee.lastname}</td>
        <td className="email">{employee.email}</td>
        <td><a href="#" onClick={this.handleDelete}>Delete Employee</a></td>
        </tr>
      );
    }
  }
});

$(->
  # JOB_BUILDER_QUESTIONS_INPUT

  if $('.checkbox.job-builder-question').length > 0
    $('.checkbox.job-builder-question input').on 'change', ->
      ans_area = $(this).closest('.job-builder-question').find('.answer-area')
      if $(this).prop('checked')
        ans_area.collapse 'show' unless ans_area.is(':visible')
      else
        ans_area.collapse 'hide' unless ans_area.is(':hidden')


  # HIDE/SHOW COLLAPSED TRIGGER CLASS

  $(document).on 'change', '.collapsed-trigger', ->
    target = $(this).closest('.collapsed-wrapper').find($(this).data('target'))
    if $(this).is(':checked')
      target.collapse('show')
    else
      target.collapse('hide')
      if $(target).data('clear') == 1
        $(target).find('input').val('')
)

window.SubmitButton = React.createClass({
  render: function() {
    var value = this.props.value;
    var classes = this.props.classes;
    var disabled = this.props.disabled;
    if (value === undefined) {
      value = "Submit";
    }
    if (classes === undefined) {
      classes = "btn btn-primary"
    }
    return(
      <div className="form-group">
        <div className="controls">
          <input type="submit" className={classes} value={value} disabled={disabled}></input>
        </div>
      </div>
    )
  }
});

window.InlineSubmitButton = React.createClass({
  render: function() {
    return(
      <span className="input-group-btn">
        <button className="btn btn-default btn-plus">
          <i className="fa fa-lg fa-plus"></i>
        </button>
      </span>
    )
  }
});

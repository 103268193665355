import _ from 'underscore'

window.LocationChooser = React.createClass
  displayName: 'LocationChooser'
  getDefaultProps: ->
    store: ItemStore.createClass '/manage/locations.json', (id)->
      "/manage/locations/#{id}.json"
    districtStore: ItemStore.createClass '/manage/districts.json', (id)->
      "/manage/districts/#{id}.json"
    regionStore: ItemStore.createClass '/manage/regions.json', (id)->
      "/manage/regions/#{id}.json"
    userStore: ItemStore.createClass '/manage/users.json', (id)->
      "/manage/users/#{id}.json"
    renderName: (item)->
      "#{item.state} - #{item.name}"
  componentDidMount: ->
    @props.districtStore.loadItems()
    @props.regionStore.loadItems()
    @props.userStore.loadItems()
  render: ->
    React.createElement ItemChooser,
      _.extend {}, @props,
        blankItem: false
        editor: LocationEditor
        label: 'Location'
        locationStore: @props.store

import _ from 'underscore'
{} = React.DOM

window.EmployeeSetComponent = React.createClass
  displayName: 'EmployeeSetComponent'
  getDefaultProps: ->
    defaultAttrs: {}
    label: ''
    renderName: (item)->
      "#{item.firstname} #{item.lastname}"
  render: ->
    React.createElement ObjectSetComponent, _.extend({}, @props)

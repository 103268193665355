$( ->
  # Switches state & zip as country changes; currently used on jobs edit and locations edit pages.

  $(document).on 'change', '.locale_switchable_country', ->
    setLocationLocale()
    false

  if $('.locale_switchable_country').length > 0
    setLocationLocale()

)

setLocationLocale = ->
  inp = $('.locale_switchable_country')
  locale = inp.data('locale')["en-#{inp.val()}"]
  replace_options('.locale_switchable_state', locale.state.options)
  replace_label("label[for='"+$('.locale_switchable_state').attr('id')+"']", locale.state.label)
  replace_label("label[for='"+$('.locale_switchable_zip').attr('id')+"']", locale.zip.label)
  $('.locale_switchable_state').attr('placeholder', locale.state.label)
  $('.locale_switchable_zip').attr('placeholder', locale.zip.label)

replace_options = (dom_element, new_options) ->
  old_val = $(dom_element).val()
  select = $('<select>')
  $.each new_options, (_index, element) ->
    select.append($('<option></option>').val(element[1]).html(element[0]))
  $(dom_element).empty().append(select.html())
  existing = $.grep new_options, (el)->
    el[1] == old_val
  $(dom_element).val(old_val) if existing.length > 0

replace_label = (dom_element, new_text) ->
  $(dom_element).text(new_text + "*")
  
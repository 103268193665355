import _ from 'underscore'
{ div, label, span, fieldset, input, select, option, i, a } = React.DOM

window.RegionSetupEditor = React.createClass
  displayName: 'RegionSetupEditor'
  render: ->
    div {},
      React.createElement ItemEditor, _.extend {}, @props,
        thing: 'district'
        name: 'District'
        store: @props.districtStore
        listLabel: 'Existing Districts'
        itemForm: DistrictModalBody
        includeLocations: true
      if @props.permissions.region
        React.createElement ItemEditor, _.extend {}, @props,
          thing: 'region'
          name: 'Region'
          store: @props.regionStore
          listLabel: 'Existing Regions'
          itemForm: RegionModalBody
          includeDistricts: true

window.DistrictEditor = React.createClass
  displayName: 'DistrictEditor'
  render: ->
    React.createElement ItemEditor, _.extend {}, @props,
      thing: 'district'
      name: 'District'
      listLabel: 'Existing Districts'
      itemForm: DistrictModalBody

window.RegionEditor = React.createClass
  displayName: 'RegionEditor'
  render: ->
    React.createElement ItemEditor, _.extend {}, @props,
      thing: 'region'
      name: 'Region'
      listLabel: 'Existing Regions'
      itemForm: RegionModalBody

UserSelect = React.createClass
  displayName: 'UserSelect'
  nonOwnerUsers: ->
    _.filter @props.users, (item)->
      parseInt(item.role) > 1
  render: ->
    div { className: 'select input optional form-group item-input', 'data-field': 'user_ids' },
      label { className: 'control-label', htmlFor: "uf_#{@props.thing}_#{@props.name}" },
        @props.label
        if @props.info?
          React.createElement MoreInfo,
            info: @props.info
      span { className: 'form-wrapper' },
        select
          id: "uf_#{@props.thing}_#{@props.name}"
          className: 'form-control'
          name: @props.inputName
          defaultValue: @props.item[@props.name]
        ,
          _.map @nonOwnerUsers(), (user)=>
            option
              value: user.id
              key: "#{@props.name}-#{user.id}"
            , "#{user.firstname} #{user.lastname}"

DistrictModalBody = React.createClass
  displayName: 'DistrictModalBody'
  getDefaultProps: ->
    includeLocations: false
  getInitialState: ->
    users: @props.userStore.getItems() or []
    locations: @props.locationStore.getItems() or []
    regions: @props.regionStore.getItems() or []
  onUsersChange: ->
    @setState
      users: @props.userStore.getItems()
  onLocationsChange: ->
    @setState
      locations: @props.locationStore.getItems()
  onRegionsChange: ->
    @setState
      regions: @props.regionStore.getItems()
  componentWillMount: ->
    @props.userStore.addListener 'change', @onUsersChange
    @props.locationStore.addListener 'change', @onLocationsChange
    @props.regionStore.addListener 'change', @onRegionsChange
  componentWillUnmount: ->
    @props.userStore.removeListener 'change', @onUsersChange
    @props.locationStore.removeListener 'change', @onLocationsChange
    @props.regionStore.removeListener 'change', @onRegionsChange
  availableLocations: ->
    _.reject @state.locations, (location)=>
      _.findWhere @props.item.locations, { id: location.id }
  render: ->
    fieldset { className: 'inputs district-editor-fieldset' },
      React.createElement ItemString,
        thing: 'district'
        item: @props.item
        name: 'name'
        label: 'Name'
        required: true
      React.createElement UserSelect,
        item: @props.item
        name: 'user_ids'
        thing: 'district'
        inputName: 'district[user_ids][]'
        label: 'District Manager'
        users: @state.users
        info: 'The District Manager will have access to all locations in the District.'
      if @state.regions.length > 0
        React.createElement RegionChooser,
          _.extend {}, @props,
            items: @state.regions
            initialSelected: @props.item.region_id
            store: @props.regionStore
            inputName: 'district[region_id]'
            renderName: (item)->
              item.name
      if @props.includeLocations
        React.createElement ObjectSetComponent,
          parentId: @props.item.id || @props.item.temp_id
          inputTemplate: 'district[location_ids][]'
          defaultAttrs: {}
          initialItems: @props.item.locations
          label: 'Locations'
          availableItems: @availableLocations()
          info: 'Add the locations that belong to this district, and the District Manager ' +
            'will have access to them.'

RegionModalBody = React.createClass
  displayName: 'RegionModalBody'
  getDefaultProps: ->
    includeDistricts: false
  getInitialState: ->
    users: @props.userStore.getItems()
    districts: @props.districtStore.getItems()
  onUsersChange: ->
    @setState
      users: @props.userStore.getItems()
  onDistrictsChange: ->
    @setState
      districts: @props.districtStore.getItems()
  componentWillMount: ->
    @props.userStore.addListener 'change', @onUsersChange
    @props.districtStore.addListener 'change', @onDistrictsChange
  componentWillUnmount: ->
    @props.userStore.removeListener 'change', @onUsersChange
    @props.districtStore.removeListener 'change', @onDistrictsChange
  availableDistricts: ->
    _.reject @state.districts, (district)=>
      _.findWhere @props.item.districts, { id: district.id }
  render: ->
    fieldset { className: 'inputs' },
      React.createElement ItemString,
        thing: 'region'
        item: @props.item
        name: 'name'
        label: 'Name'
        required: true
      React.createElement UserSelect,
        item: @props.item
        name: 'user_ids'
        thing: 'region'
        inputName: 'region[user_ids][]'
        label: 'Region Manager'
        users: @state.users
        info: 'The Region Manager will have access to all locations in all the districts in ' +
          'the Region.'
      if @props.includeDistricts
        React.createElement ObjectSetComponent,
          parentId: @props.item.id || @props.item.temp_id
          inputTemplate: 'region[district_ids][]'
          defaultAttrs: {}
          initialItems: @props.item.districts
          label: 'Districts'
          availableItems: @availableDistricts()

import Clipboard from 'clipboard'

clipboard_builder = (target, opts) ->
  opts = {} unless opts?
  board = new Clipboard(target, opts)

  board.on 'success', (e) ->
    trigger = $(e.trigger)
    trigger.tooltip({
      title: 'Copied To Clipboard!',
      trigger: 'manual',
      placement: 'bottom'
    })

    trigger.tooltip('show');

    timeout = setTimeout ->
      trigger.tooltip('hide')
      trigger.tooltip('destroy')
    , 2000

  board.on 'error', (e) ->
    trigger = $(e.trigger)

    trigger.tooltip({
      title: 'Hightlight text and press Ctrl + C',
      trigger: 'manual',
      placement: 'bottom'
    })

    trigger.tooltip('show');

    timeout = setTimeout ->
      trigger.tooltip('hide')
      trigger.tooltip('destroy')
    , 2000

export default {
  build: clipboard_builder
};

{ div, label, span, fieldset, input, select, option } = React.DOM

window.UserEditor = React.createClass
  displayName: 'UserEditor'
  userName: (user)->
    "#{user.firstname} #{user.lastname}"
  render: ->
    React.createElement ItemEditor,
      thing: 'user'
      name: 'User'
      store: @props.store
      listLabel: 'Existing Users'
      itemForm: UserModalBody
      renderName: @userName

UserModalBody = React.createClass
  displayName: 'UserModalBody'
  render: ->
    fieldset { className: 'inputs' },
      div { className: 'row' },
        div { className: 'col-md-6' },
          React.createElement ItemString,
            thing: 'user'
            item: @props.item
            name: 'firstname'
            label: 'First Name'
            required: true
        div { className: 'col-md-6' },
          React.createElement ItemString,
            thing: 'user'
            item: @props.item
            name: 'lastname'
            label: 'Last Name'
            required: true
      React.createElement ItemEmail,
        thing: 'user'
        item: @props.item
        name: 'email'
        label: 'Email'
        required: true
      if @props.item.role? && @props.item.role < 2
        div {}, ''
      else
        React.createElement ItemSelect,
          thing: 'user'
          item: @props.item
          name: 'role'
          label: 'Access to Recruiting System'
          options: [['3', 'Limited Access (Can review applicants)'],
                    ['2', 'Full Access (Can review applicants/post jobs)'],
                    ['1', 'Admin (for owners/managers only)']]
          info: 'Limited Access users can only review applicants for locations they are added ' +
            "to.\nFull Access users can post jobs and review applicants for their locations.\n" +
            'Admin users automatically have access to all jobs, locations, districts, and ' +
            'departments.'

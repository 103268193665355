$(->
  $('fieldset.input-switch .form-group.input').not('.error').hide()
  if $('fieldset.input-switch .form-group.error').length < 1
    $('fieldset.input-switch .form-group.initial').show()
  $('fieldset.input-switch a.other').click((ev)->
    ev.preventDefault()
    $('#' + $(this).attr('data-other')).show()
    return false
  )
  $('fieldset.input-switch .form-group.input a.other').click((ev)->
    ev.preventDefault()
    $(this).closest('.form-group.input').hide()
    false
  )

  ['.panel-group.switch-inputs', '.toggle-editor'].forEach (clazz)->
    $("#{clazz} .collapse").on 'hide.bs.collapse', ->
      $(this).find('input,select,textarea').prop('disabled', true)
    $("#{clazz} .collapse:not(.in)").trigger('hide.bs.collapse')

    $("#{clazz} .collapse").on 'show.bs.collapse', ->
      $(this).find('input,select,textarea').prop('disabled', false)

  $(document).on 'click', '.toggle-editor [data-toggle="collapse"]', ->
    $(this).closest('form').find('.editor.in').collapse('hide')
    $(this).closest('form').find('.viewer:not(.in)').collapse('show')
    $(this).closest('.toggle-editor .viewer').collapse('hide')
    false
)
